<template>
  <div class="home">
    <!-- <img class="demo" src="/img/demo/home.jpg" /> -->
    <Swiper :options="swiperOptions">
      <SwiperSlide>
        <img src="/img/home/swiper/1.jpg" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="/img/home/swiper/2.jpg" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="/img/home/swiper/3.jpg" />
      </SwiperSlide>
      <div class="swiper-pagination" slot="pagination"></div>
    </Swiper>
    <div class="p1w relative">
      <img id="hp1" class="block mt-50 p1" src="/img/home/1.jpg" />
      <div class="absolute">
        <img src="/img/home/1-1.png" />
      </div>
    </div>
    <div class="mt-50 relative p2w">
      <img class="block p2" src="/img/home/2.jpg" />
      <div class="absolute">
        <router-link to="/pro#pro_p9">
          <img data-aos="fade-up" src="/img/home/2-1.png" />
        </router-link>
      </div>
    </div>
    <div class="relative p3w">
      <img id="hp3" class="block mt-60 p3" src="/img/home/3.png" />
      <div class="absolute">
        <router-link to="/pro">
          <img data-aos="fade-up" src="/img/home/3-1.png" />
        </router-link>
      </div>
    </div>

    <div id="hp4" class="relative p4">
      <img class="block mt-60" src="/img/home/4.jpg" @load="load" />
      <div class="absolute">
        <img data-aos="fade-up" src="/img/home/4-1.png" />
      </div>
      <div class="content">
        <div class="title">荟萃顶尖人才</div>
        <div class="flex list">
          <div class="item item-1">
            <div class="flex text">
              <ICountUp
                class="num"
                ref="item-1"
                :endVal="500"
                :delay="-1"
                :options="countUpOptions"
              />
              <div class="add">+</div>
            </div>
            <div class="info">自有工程师团队</div>
          </div>
          <div class="item item-2">
            <div class="flex text">
              <ICountUp
                class="num"
                ref="item-2"
                :endVal="30"
                :delay="-1"
                :options="countUpOptions"
              />
              <div class="add">+</div>
            </div>
            <div class="info">紧密合作的各领域专家工程师</div>
          </div>
          <div class="item item-3">
            <div class="flex text">
              <ICountUp
                class="num"
                ref="item-3"
                :endVal="30"
                :delay="-1"
                :options="countUpOptions"
              />

              <div class="unit">万</div>
              <div class="add">+</div>
            </div>
            <div class="info">专业人才的行业数据库</div>
          </div>
        </div>
      </div>
    </div>
    <div class="relative p5w">
      <img class="block" src="/img/home/5.jpg" />
    </div>
    <div class="relative p6w">
      <img id="hp6" class="block p6" src="/img/home/6.jpg" />
      <div class="absolute">
        <img data-aos="fade-up" src="/img/home/6-1.png" />
      </div>
    </div>

    <div class="relative p7">
      <img class="block" src="/img/home/7.jpg" />
      <a href="/case"></a>
    </div>

    <div id="hp8" class="p8-wrap relative">
      <div class="absolute">
        <img
          data-aos="fade-up"
          data-aos-anchor=".p8"
          class="block p8-t"
          src="/img/home/8.png"
        />
      </div>
      <div class="p8">
        <div class="flex flex-nowrap justify-between">
          <div class="flex flex-col justify-start items-end p8-left">
            <div class="p8-1 relative mask">
              <img src="/img/home/8-1.jpg" />
              <div class="flex flex-col justify-center items-center">
                <div>根据客户需求制定解决方案，</div>
                <div>协助客户解决项目难题，</div>
                <div>提供整体及单项技术支持服务</div>
              </div>
            </div>
            <div class="p8-2 relative mask">
              <img src="/img/home/8-2.jpg" />
              <div class="flex flex-col justify-center items-center">
                <div>根据客户项目需求，完成项目招聘整个流程，</div>
                <div>从岗位分析、人才画像、人才筛选、人才评测、</div>
                <div>面试安排至人才录用</div>
              </div>
            </div>
          </div>
          <div class="p8-3 relative mask">
            <img src="/img/home/8-3.jpg" />
            <div class="flex flex-col justify-center items-center">
              <div>
                <span style="font-size: 1.8rem">300000+</span>
                人才资源库，
              </div>
              <div>可完成人才精准匹配，</div>
              <div>高效完成订单式人才交付</div>
            </div>
          </div>
          <div class="p8-4 relative mask">
            <img src="/img/home/8-4.jpg" />
            <div
              style="height: 82.5%"
              class="flex flex-col justify-center items-center"
            >
              <div>承接国内/国外客户部分或</div>
              <div>全部管理及运营流程，</div>
              <div>提高客户刘成自动化能力，</div>
              <div>降低客户运营成本</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <img class="block" src="/img/home/9.jpg" />
  </div>
</template>
<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'
  import { debounce } from 'lodash'
  import ICountUp from 'vue-countup-v2'
  import aos from '@/mixins/aos'
  import { getPageOffsetY, getScrollTop } from '@/utils/dom'
  export default {
    components: {
      Swiper,
      SwiperSlide,
      ICountUp,
    },
    mixins: [aos],
    data() {
      return {
        swiperOptions: {
          loop: true,
          autoplay: {
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
            delay: 6000,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
        },
        countUpOptions: {
          startVal: 0,
          duration: 3,
          separator: '',
        },
      }
    },
    methods: {
      load(e) {
        console.info(getPageOffsetY)
        console.info(e.target)
        const y = getPageOffsetY(e.path ? e.path[0] : e.target)

        const debounced = debounce(() => {
          let scrollTop = getScrollTop()

          if (scrollTop + document.documentElement.clientHeight - 300 > y) {
            this.start()
            document.removeEventListener('scroll', debounced)
          }
        }, 100)

        document.addEventListener('scroll', debounced)
      },
      start() {
        this.$refs['item-1'] && this.$refs['item-1'].start()
        this.$refs['item-2'] && this.$refs['item-2'].start()
        this.$refs['item-2'] && this.$refs['item-3'].start()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .swiper-pagination {
    bottom: 45px;
    ::v-deep {
      .swiper-pagination-bullet {
        width: 10pt;
        height: 10pt;
        margin: 0 10pt;
      }
      .swiper-pagination-bullet-active {
        background-color: #ff6c00;
      }
    }
  }
  .swiper-container {
    transform: translate3d(0, 0, 0);
  }
  .swiper-slide {
    transform: translate3d(0, 0, 0);
    box-sizing: content-box;
    padding-left: 1%;
    margin-left: -1%;
  }
  .p1w {
    .absolute {
      top: 40%;
      left: 19%;
      width: 25%;
    }
  }
  .p2w {
    .absolute {
      top: 9.7%;
      left: 39.8%;
      width: 22.6%;
    }
  }
  .p3w {
    margin: 300px auto 300px;
    .absolute {
      top: -14%;
      left: 39.5%;
      width: 22.6%;
    }
    .p3 {
      width: 85.4%;
      margin: 0 auto;
    }
  }

  .p4 {
    .absolute {
      top: 0.3%;
      left: 44.8%;
      width: 10.3%;
    }
    .content {
      position: absolute;
      top: 39.5%;
      left: 21.1%;
      color: #58698f;
      border-color: #58698f;

      .title {
        font-size: 36px;
        letter-spacing: 4px;
      }
      .list {
        margin-top: 95px;

        .item {
          padding-left: 30px;
          border-left: 2px solid;
          .text {
            .num {
              position: relative;
              display: inline-block;
              font-size: 134px;
              line-height: 80px;
            }
            .unit {
              font-size: 110px;
              line-height: 80px;
            }
            .add {
              font-size: 60px;
              line-height: 80px;
            }
          }
          .info {
            padding-left: 10px;
            margin-top: 36px;
            font-size: 18px;
            letter-spacing: 1px;
          }
        }
        .item-1 {
          width: 440px;
          .num {
            text-align: right;
          }
        }
        .item-2 {
          width: 390px;
          .num {
            text-align: right;
          }
        }
        .item-3 {
          width: 390px;
          .num {
            text-align: right;
          }
        }
      }
    }
  }
  .p5w {
    .absolute {
      top: 0.7%;
      left: 39.5%;
      width: 22.6%;
    }
  }
  .p6w {
    .p6 {
      width: 1330px;
      padding-top: 150px;
      margin: 180px auto 220px;
    }
    .absolute {
      top: 0%;
      left: 42.5%;
      width: 14.8%;
    }
  }

  .p7 a {
    position: absolute;
    top: 80.4%;
    right: 56.3%;
    bottom: 13.5%;
    left: 32%;
  }
  .p8-wrap {
    padding-top: 235px;
    padding-bottom: 100px;
    margin-top: 100px;
    margin-bottom: 90px;
    background-color: #f0f2f9;
    .absolute {
      top: 12%;
      left: 40.5%;
      width: 18.7%;
    }
    .p8 {
      width: 68%;
      margin: 0 auto;

      .p8-t {
        width: 27%;
        margin: 0 auto 85px;
      }
      .p8-left {
        width: 46.5%;
      }
      .p8-1 {
        width: 87%;
      }
      .p8-2 {
        margin-top: 20px;
      }
      .p8-3 {
        width: 25%;
      }
      .p8-4 {
        width: 25%;
      }
      .mask {
        overflow: hidden;

        > div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          font-size: 1.1rem;
          font-weight: 500;
          color: #fff;
          background-color: rgba($color: #f86f0c, $alpha: 0.9);
          transform: translateX(-105%);
          > div {
            margin-bottom: 0.8rem;
          }
        }
        &:hover > div {
          //transition: transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
          transform: translateX(0);
        }
      }
    }
  }
</style>
